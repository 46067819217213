import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { TextField, Button, Autocomplete } from '@mui/material';
import CustomSelect from '../../Layout/Select/index';
import classNames from 'classnames';
import c from '../shared.Module.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { postApiCall } from '../../../utils/axios/axios';
import { getJoinedNeighborhood } from '../../../helpers/Profile/JoinedNeighborhood';
import { getAccountsData } from '../../../helpers/Home/CreateAccount/DropdownMenu/ProfileMenu';
const JoinCorporateNew = (props) => {
  const [formData, setFormData] = React.useState({
    neighbourhood_id: '',
    corporateId: '',
  });
  const [resData, setResData] = React.useState('');
  const [validationErr, setValidationErr] = React.useState(false);
  const [buttonMessage, setButtonMessage] = React.useState('');
  const { corporateClients, generalInformation } = useSelector(
    (state) => state
  );
  const [inputValue, setinputValue] = React.useState('');
  const [openOptPop, setopenOptPop] = React.useState(true);

  const selectLabel = Object.entries(corporateClients).map((val, index) => {
    return { value: val[0], label: val[1].user_name };
  });

  const selectValue = Object.entries(corporateClients).map((val) => {
    return { label: val[1].user_name };
  });

  const handlePost = async () => {
    if (
      formData.neighbourhood_id &&
      formData.corporateId &&
      formData.neighbourhood_id === formData.corporateId
    ) {
      setValidationErr(false);
      try {
        const reqBody = {
          client_id: generalInformation.client_id,
          neighbourhood_id: formData.neighbourhood_id, //Use CLIENT_ID from Config
          user_id: generalInformation.user_id,
          user_name: generalInformation.first_name,
          role_name:
            generalInformation.network === 'users'
              ? 'USER'
              : generalInformation.network === 'clients' &&
                generalInformation.client_id === null
              ? 'MANAGER'
              : 'TEACHER',
          actual_id: generalInformation.id,
        };

        const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
        const response = await postApiCall(
          API_URLS?.addUserToNeighborhood,
          reqBody,
          paramsData
        );
        setResData(response);
        props.getJoinedNeighborhoodData();
        await getJoinedNeighborhood();
        props.setJoinComp(false);
        await getAccountsData();
      } catch (err) {
        console.log('Error in getAccounts Data ', err);
      }
    } else if (
      formData.neighbourhood_id === '' ||
      formData.corporateId === ''
    ) {
      if (formData.neighbourhood_id === '') {
        setButtonMessage('Please select corporate a Id');
      } else {
        setButtonMessage('Corporate Id cannot be blank');
      }
    } else {
      setValidationErr(true);
    }
  };

  return (
    <Modal
      open={props.showJoinCom}
      className="d-flex align-items-center"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableBackdropClick
      onClose={() => props.setJoinComp(false)}
      // onClick={(e) => {
      //   e.stopPropagation();
      // }}
    >
      <div
        className={classNames(
          'row g-0 justify-content-center flex-wrap mx-auto',
          c.sessionDetailWhite
        )}
      >
        <div
          className={classNames(
            'col-xl-6 text-white rounded-start',
            c.firstBox
          )}
        >
          <div className="mt-2 text-end">
            <IconButton
              onClick={() => {
                props.setJoinComp(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <h3
            classToOverride="pt-5 fw-bold"
            className={classNames('text-center', c.headingColor)}
          >
            Join Organization
          </h3>

          <div className="row justify-content-center mt-5">
            <div className="row justify-content-center">
              <Autocomplete
                className="w-75"
                sx={{ padding: '0px' }}
                inputValue={inputValue}
                popupIcon={''}
                onInputChange={(e, newValue) => {
                  setopenOptPop(true);
                  setinputValue(newValue);
                }}
                onChange={(e, newValue) => {
                  setopenOptPop(false);
                  setFormData((prev) => ({
                    ...prev,
                    neighbourhood_id:
                      newValue && newValue.value ? newValue.value : '',
                  }));
                }}
                id="neighbourhood_id"
                options={selectLabel}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type Organization Name"
                    variant="outlined"
                  />
                )}
                open={inputValue.length > 2 && openOptPop}
              />
            </div>
            <div className="row justify-content-center">
              <TextField
                size="small"
                id="outlined-basic"
                label="Enter Code"
                variant="outlined"
                sx={{ width: '75%', mt: 5 }}
                error={validationErr}
                helperText={validationErr ? 'Invalid Organization Id' : ''}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove all spaces
                  setFormData((prev) => ({
                    ...prev,
                    corporateId: sanitizedValue,
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault(); // Prevent spacebar input
                  }
                  e.stopPropagation();
                }}
              />
            </div>

            <div className="row justify-content-center">
              <Button
                className={classNames(c.ButtonCreate)}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePost();
                }}
                sx={{ width: '25%', mt: 5 }}
                disabled={
                  formData.neighbourhood_id && formData.corporateId
                    ? false
                    : true
                }
              >
                Join
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default JoinCorporateNew;
