import React, { useState } from 'react';
import c from './signIn.Module.scss';
import classNames from 'classnames';
import Headings from '../Shared/Headings';
import { TextField, Button, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { initialValues, validate, onSubmit } from '../../helpers/signIn';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Text from '../Shared/Text';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Form from 'react-bootstrap/Form';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { VisibilityOff } from '@mui/icons-material';
// import { Footer } from './Footer';

const styles = makeStyles((theme) => ({
  label: {
    fontSize: '18px',
  },
  root: {
    '& .MuiFilledInput-root': {
      background: '#c6d3d2',
    },
  },
}));

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      onSubmit(values, history, setIsLoading, dispatch);
      googleAnalyticsTriggerFn(
        'Button',
        'Login',
        'Login button clicked on sign in page'
      );
    },
  });
  const classes = styles();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);

  return (
    <div className={classNames(c.singninContainer, 'py-5')}>
      <div className="d-flex flex-col align-items-center w-100">
        <div
          className="d-flex flex-row justify-content-center w-100"
          style={{ height: 'fit-content' }}
        >
          <div
            className={c.cursorPointer}
            onClick={() => {
              history.push('/');
            }}
          >
            <CloseIcon
              sx={{
                backgroundColor: '#232323',
                color: '#ffffff',
              }}
              className={classNames(
                'rounded-circle p-1 m-1 mx-2 cursor-na',
                c.crossIcon
              )}
            />
          </div>
          <form
            className={classNames(c.signIn, 'pb-5')}
            onSubmit={formik.handleSubmit}
          >
            <Headings
              headingType="h36"
              classToOverride={classNames('fw-bold mb-3')}
            >
              Login
            </Headings>
            <Headings
              headingType="h5"
              classToOverride={classNames(c.subHeading, 'fw-light')}
            >
              Enter your account credentials to login.
            </Headings>
            <div className="mt-4">
              <TextField
                variant="filled"
                label="EMAIL"
                className={classNames(classes.root, 'w-100')}
                id="email"
                name="email"
                onBlur={formik?.handleBlur}
                value={formik?.values?.email}
                onChange={formik?.handleChange}
                error={formik?.errors?.email ? true : false}
                helperText={formik?.errors?.email}
              />
            </div>
            <div className="mt-4">
              <TextField
                variant="filled"
                label="PASSWORD"
                type={isPasswordVisible ? 'text' : 'password'}
                className={classNames(classes.root, 'w-100')}
                id="pass"
                name="pass"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => setisPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? <VisibilityOff /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: ` fw-bold ${c.signUpFields}`,
                }}
                value={formik?.values?.pass}
                onBlur={formik?.handleBlur}
                onChange={formik?.handleChange}
                error={formik?.errors?.pass ? true : false}
                helperText={formik?.errors?.pass}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-5">
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={
                  <Form.Check
                    type="checkbox"
                    id={`check-api-checkbox`}
                    className="mx-2"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      isValid
                      className={c.crossIcon}
                    />
                  </Form.Check>
                }
                label="Remember Me"
              />
              <Text textType="t18">
                <Link
                  to="/forgot-password"
                  onClick={() => {
                    googleAnalyticsTriggerFn(
                      'Hyperlink',
                      'Forgot Password',
                      'Forgot password link clicked on sign in page'
                    );
                  }}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {' '}
                  Forgot Password{' '}
                </Link>
              </Text>
            </div>
            <div className="mb-4 pb-3 text-center">
              <Button
                variant="contained"
                className={classNames('w-100', c.loginBtn)}
                type="submit"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <h5 className="fw-bold d-inline pt-2">{'Login'}</h5>
                )}
              </Button>
            </div>
            <div className="text-center">
              <Text textType="t18">
                <Link
                  to="/signUp"
                  onClick={() => {
                    googleAnalyticsTriggerFn(
                      'Hyperlink',
                      'Sign up',
                      'Create Account link clicked on sign in page'
                    );
                  }}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {' '}
                  Create Account{' '}
                </Link>
              </Text>
            </div>

            <div className="text-center mt-3">
              <Text textType="t14" className="text-muted">
                Contact <a href="mailto:hi@ahuraai.com" style={{ color: '#fff' }}>hi@ahuraai.com</a> if any issues.
              </Text>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
